<template>
  <div class="sub-page" id="pdf">
    <div class="header">
      <img src="@/assets/images/pdf/logo.png" alt="" width="180">
    </div>
    <div class="calender">
      <div class="calender-top">
        <h3 class="calender-top__tit"></h3>
      </div>
      <div class="calender-blocks">
        <div class="calender-block"
          v-for="(month, index) in monthes"
          :key="month.name"
          :class="{'calender-block--active' : month.user_image}"
        >
          <p class="calender-block__month">
            {{ month.name }}
          </p>
          <div class="calender-block__img-block">
            <img :src="imgs[index]"
              alt="img" class="calender-block__img"
              :class="'calender-block__img--' + index"
              >
            <div class="calender-block__desire">
              <img :src="month.user_image ? month.user_image.replace('https://500405.selcdn.ru/', '') : ''" alt="img"
                class="calender-block__desire-img">
            </div>
          </div>
          <p class="calender-block__desire-txt">
            {{ month.user_text }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import jan from '@/assets/images/pdf/january.png';
import feb from '@/assets/images/pdf/february.png';
import mar from '@/assets/images/pdf/march.png';
import apr from '@/assets/images/pdf/april.png';
import may from '@/assets/images/pdf/may.png';
import jun from '@/assets/images/pdf/june.png';
import jul from '@/assets/images/pdf/july.png';
import aug from '@/assets/images/pdf/august.png';
import sep from '@/assets/images/pdf/september.png';
import oct from '@/assets/images/pdf/october.png';
import nov from '@/assets/images/pdf/november.png';
import dec from '@/assets/images/pdf/december.png';

export default {
  props: {
    monthes: {
      type: Array,
    },
  },
  data() {
    return {
      imgs: [
        jan,
        feb,
        mar,
        apr,
        may,
        jun,
        jul,
        aug,
        sep,
        oct,
        nov,
        dec,
      ],
    };
  },
};
</script>
<style>
#pdf {
  position: absolute;
  top: -400cm;
}
</style>
<style scoped>
.sub-page {
  height: 297mm;
  width: 210mm;
  padding: 0 1cm;
  box-sizing: border-box;
}
.header {
  margin-bottom: 21px;
}
.header img {
  display: block;
  width: 95px;
  height: 25px;
}
.header__logo {
  display: block;
  width: 90px;
  height: 24px;
  margin: 0 auto;
}
.calender {
  border: 1px solid #DFB3E6;
  box-sizing: border-box;
  border-radius: 20px;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
}

.calender-block {
  width: calc(33.33% - 60px);
  margin: 10px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding-bottom: 4rem;
}

.calender-block--active .calender-block__desire {
  display: block;
}

.calender-block--active .calender-block__desire-txt {
  display: block;
}

.calender-block__desire {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
}

.calender-block__desire-img {
  width: 110px;
}

.calender-block__desire-txt {
  font-weight: 500;
  font-size: 12px;
  line-height: 1.44;
  color: #121212;
  text-align: center;
  display: none;
}

.calender-block__month {
  font-weight: normal;
  font-size: 24px;
  line-height: 1;
  color: #121212;
}

.calender-blocks {
  display: flex;
  flex-wrap: wrap;
  padding: 0 30px;
  margin: -10px -30px;
}

.calender-block__img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 104px;
  height: 70px;
}

.calender-block__img--2 {
  width: 93px;
  height: 75px;
}

.calender-block__img--3 {
  width: 88px;
  height: 74px;
}

.calender-block__img--4 {
  width: 106px;
  height: 67px;
}

.calender-block__img--5 {
  width: 89px;
  height: 92px;
}

.calender-block__img--6 {
  width: 102px;
  height: 69px;
}

.calender-block__img--7 {
  width: 96px;
  height: 76px;
}

.calender-block__img--8 {
  width: 95px;
  height: 76px;
}

.calender-block__img--9 {
  width: 102px;
  height: 69px;
}

.calender-block__img--10 {
  width: 97px;
  height: 81px;
}

.calender-block__img--11 {
  width: 94px;
  height: 76px;
}

.calender-block__img--12 {
  width: 102px;
  height: 69px;
}

.calender-block__img-block {
  width: 110px;
  height: 90px;
  position: relative;
  margin: 18px 0 23px;
}

.calender-top {
  background: #DFB3E6;
  border-radius: 40px;
  width: 100%;
  padding: 8px 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  top: 0;
  margin-bottom: 24px;
}

.calender-top__tit {
  background: url("../assets/images/pdf/2022.png") no-repeat;
  background-size: contain;
  width: 73px;
  height: 24px;
  margin: 0;
}

</style>
