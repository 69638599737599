<template>
  <div class="page-wrapper" :class="{'page-wrapper--fixed' : !isLoggedIn}">
    <div class="container">
      <header-block />
      <div class="celender">
        <div class="celender-top">
          <h3 class="celender-top__tit">
            2 <span class="celender-top__tit-icon"></span> 22
          </h3>
          <button class="celender-top__edit">
            Редактировать календарь
          </button>
        </div>
        <div class="celender-blocks">
          <div
            class="celender-block"
            :class="{'celender-block--active' : month.user_image}"
            v-for="(month, index) in monthes"
            :key="month.name"
          >
            <p class="celender-block__month">
              {{ month.name }}
            </p>
            <div class="celender-block__img-block">
              <img :src="month.background" alt="img"
                class="celender-block__img"
                :class="' celender-block__img--' + (index + 1)"
              >
              <div class="celender-block__desire">
                <img :src="month.user_image"
                  alt="img" class="celender-block__desire-img">
                <button class="celender-block__delete" @click.prevent="removeUserImage(index)"/>
              </div>
            </div>
            <p class="celender-block__desire-txt">
              {{ month.user_text }}
            </p>
            <button class="button" @click.prevent="addDream(index)">
              Добавить желание
            </button>
          </div>
        </div>
      </div>
      <div class="download">
        <div class="select-format">
          <select v-model="format">
            <option value="a4">Размер A4</option>
            <option value="a3">Размер A3</option>
          </select>
        </div>
        <div class="download__link-block">
          <a href="#" class="download__link" @click.prevent="savePdf">
            <span>{{ savingPdf ? 'Загрузка...' : 'Скачать PDF' }}</span>
          </a>
          <span class="download__icon"></span>
        </div>
      </div>
    </div>
    <footer-block />
    <div class="desire" v-if="selectedMonth || selectedMonth === 0">
      <div class="desire-block">
        <div class="desire-block__tit">
          Добавить желание
        </div>
        <form class="desire-add__form" @submit.prevent="saveToServer">
          <div class="gallery"
            :class="{'gallery--active' : isShowGallery}"
          >
            <div v-if="isShowGallery && !galleryImages.length">
              <a href="#" @click.prevent="selectGallery('view')">Внешность</a>
              <a href="#" @click.prevent="selectGallery('money')">Деньги</a>
              <a href="#" @click.prevent="selectGallery('house')">Дом</a>
              <a href="#" @click.prevent="selectGallery('health')">Здоровье</a>
              <a href="#" @click.prevent="selectGallery('love')">Любовь к себе</a>
              <a href="#" @click.prevent="selectGallery('ny')">Новый год и рождество</a>
              <a href="#" @click.prevent="selectGallery('relation')">Отношения</a>
              <a href="#" @click.prevent="selectGallery('p')">Призвание</a>
              <a href="#" @click.prevent="selectGallery('life')">Яркость жизни</a>
            </div>

            <div class="gallery__images">
              <img :src="i" alt=""
                v-for="i in galleryImages"
                :key="i"
                class="gallery__image"
                @click.prevent="selectImage(i)"
              >
            </div>
            <button class="button button--add"
              style="width: 100%;display: block;margin: 3rem 0"
              @click.prevent="showGallery"
              v-if="!galleryImages.length"
            >
              {{ isShowGallery ? 'Назад' : 'Выбрать из галереи' }}
            </button>

            <button class="button button--add"
              style="width: 100%;display: block;margin: 3rem 0 3rem"
              @click.prevent="hideGalleryImages"
              v-else
            >
              Назад
            </button>
          </div>
          <div class="desire-add">
            <div class="desire-add__file">
              <label for="file" class="desire-add__file-label"
                v-show="!isShowGallery"
                :class="{'desire-add__file-label--gallery' : selectedGalleryImage || showImage}"
                >
                <input id="file" type="file" class="desire-add__file-input"
                  accept="image/*"
                  @change="fileChanged"
                  ref="file"
                >
                <span>Выбрать фото</span>
              </label>
              <div class="desire-add__blocks" v-show="showImage">
                <div class="desire-add__block">
                  <img ref="image"
                    alt="img" class="desire-add__block-img">
                  <button
                    class="celender-block__delete"
                    @click.prevent="removeImage"
                  ></button>
                </div>
              </div>
            </div>
            <div class="desire-add__info">
              <textarea class="desire-add__info-textarea"
                placeholder="Напишите тут свои мечты..." required v-model="message"
                maxlength="70"
              ></textarea>
              <div class="desire-add__info-buttons">
                <button class="button button--add" type="submit" :disabled="uploading">
                  {{ uploading ? 'Загрузка...' : 'Добавить желание' }}
                </button>
              </div>
            </div>
          </div>
        </form>
        <button class="desire-block__back" @click.prevent="hidePopup"></button>
      </div>
    </div>
    <login @setLoggedIn="setLoggedIn" v-if="!isLoggedIn"/>
    <pdf :monthes="monthes"/>
  </div>
</template>

<script>
import Cropper from 'cropperjs';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import 'cropperjs/dist/cropper.css';
import Login from '@/components/Login.vue';
import HeaderBlock from '@/components/Header.vue';
import FooterBlock from '@/components/Footer.vue';
import Pdf from '@/components/Pdf.vue';
import jan from '@/assets/images/january.svg';
import feb from '@/assets/images/february.svg';
import mar from '@/assets/images/march.svg';
import apr from '@/assets/images/april.svg';
import may from '@/assets/images/may.svg';
import jun from '@/assets/images/june.svg';
import jul from '@/assets/images/july.svg';
import aug from '@/assets/images/august.svg';
import sep from '@/assets/images/september.svg';
import oct from '@/assets/images/october.svg';
import nov from '@/assets/images/november.svg';
import dec from '@/assets/images/december.svg';

export default {
  name: 'Home',
  components: {
    Login,
    HeaderBlock,
    FooterBlock,
    Pdf,
  },
  data() {
    return {
      isLoggedIn: false,
      monthes: [{
        name: 'Январь',
        background: jan,
        user_image: null,
        user_text: null,
      }, {
        name: 'Февраль',
        background: feb,
        user_image: null,
        user_text: null,
      }, {
        name: 'Март',
        background: mar,
        user_image: null,
        user_text: null,
      }, {
        name: 'Апрель',
        background: apr,
        user_image: null,
        user_text: null,
      }, {
        name: 'Май',
        background: may,
        user_image: null,
        user_text: null,
      }, {
        name: 'Июнь',
        background: jun,
        user_image: null,
        user_text: null,
      }, {
        name: 'Июль',
        background: jul,
        user_image: null,
        user_text: null,
      }, {
        name: 'Август',
        background: aug,
        user_image: null,
        user_text: null,
      }, {
        name: 'Сентябрь',
        background: sep,
        user_image: null,
        user_text: null,
      }, {
        name: 'Октябрь',
        background: oct,
        user_image: null,
        user_text: null,
      }, {
        name: 'Ноябрь',
        background: nov,
        user_image: null,
        user_text: null,
      }, {
        name: 'Декабрь',
        background: dec,
        user_image: null,
        user_text: null,
      }],
      selectedMonth: null,
      image: null,
      cropper: null,
      showImage: false,
      message: null,
      uploading: false,
      isShowGallery: false,
      gallery: null,
      galleryImages: [],
      imgageCount: {
        view: 23,
        money: 32,
        house: 30,
        health: 11,
        life: 56,
        p: 26,
        relation: 31,
        ny: 27,
        love: 25,
      },
      selectedGalleryImage: null,
      savingPdf: false,
      format: 'a4',
    };
  },
  mounted() {
  },
  methods: {
    showGallery() {
      this.isShowGallery = !this.isShowGallery;
    },
    hideGalleryImages() {
      this.galleryImages = [];
    },
    selectImage(image) {
      this.galleryImages = [];
      this.isShowGallery = false;
      this.$refs.image.src = image;
      this.showImage = true;
      this.selectedGalleryImage = image;
    },
    selectGallery(gallery) {
      for (let i = 1; i < this.imgageCount[gallery]; i += 1) {
        this.galleryImages.push(
          `https://500405.selcdn.ru/lerchek_photo/calendar/gallery/${gallery}/f${i}.jpg`,
        );
      }
    },
    async setLoggedIn() {
      this.isLoggedIn = true;
      const response = await this.$request.get('/api/guide/calendar', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (response.data.success && response.data.result
        && response.data.result && response.data.result.calendar
      ) {
        const calendar = JSON.parse(response.data.result.calendar);

        calendar.forEach((c, index) => {
          this.$set(this.monthes[index], 'user_image', c.p);
          this.$set(this.monthes[index], 'user_text', c.t);
        });
      } else if (!response.data.success && response.data.code === 401) {
        localStorage.setItem('token', '');
        this.isLoggedIn = false;
      }
    },
    addDream(index) {
      this.selectedMonth = index;
    },
    removeImage() {
      this.showImage = false;
      if (this.cropper) {
        this.cropper.destroy();
      }
      this.cropper = null;
      this.$refs.file.value = null;
      this.selectedGalleryImage = null;
    },
    fileChanged(e) {
      const { files } = e.target;
      const done = (url) => {
        this.$refs.image.src = url;
        this.showImage = true;
      };
      let reader;
      let file;

      if (files && files.length > 0) {
        [file] = files;

        if (URL) {
          done(URL.createObjectURL(file));
        } else if (FileReader) {
          reader = new FileReader();
          reader.onload = () => {
            done(reader.result);
          };
          reader.readAsDataURL(file);
        }
      }
      this.cropper = new Cropper(this.$refs.image, {
        aspectRatio: 1,
        viewMode: 3,
      });
    },
    async saveToServer() {
      try {
        this.uploading = true;
        if (this.cropper) {
          const canvas = this.cropper.getCroppedCanvas({
            width: 1000,
            height: 1000,
          });
          const blob = await this.getCanvasBlob(canvas);
          const formData = new FormData();
          formData.append('file', blob);
          const response = await this.$request.post('/api/guide/file/upload/calendar', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          });

          if (!response.data.success) {
            // eslint-disable-next-line
            alert('Произошла ошибка при загрузке файла');
            this.uploading = false;
            return;
          }
          this.$set(this.monthes[this.selectedMonth], 'user_image', response.data.result);
        } else {
          this.$set(this.monthes[this.selectedMonth], 'user_image', this.selectedGalleryImage);
        }
        this.$set(this.monthes[this.selectedMonth], 'user_text', this.message);

        const data = [];
        this.monthes.forEach((m) => {
          data.push({
            p: m.user_image,
            t: m.user_text,
          });
        });
        const saveResponse = await this.$request.post('/api/guide/calendar', {
          calendar: data,
        }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        if (!saveResponse.data.success) {
          // eslint-disable-next-line
          alert('Произошла ошибка при загрузке файла');
          this.uploading = false;
          return;
        }

        if (this.cropper) {
          this.cropper.destroy();
        }
        this.cropper = null;
        this.selectedMonth = null;
        this.showImage = false;
        this.message = null;
        this.selectedGalleryImage = null;
      } catch (e) {
        console.log(e);
        // eslint-disable-next-line
        alert('Произошла ошибка при загрузке файла');
      } finally {
        this.uploading = false;
      }
    },
    removeUserImage(index) {
      this.$set(this.monthes[index], 'user_image', null);
      this.$set(this.monthes[index], 'user_text', null);
    },
    hidePopup() {
      this.selectedMonth = null;
      this.showImage = false;
      this.message = null;
    },
    getCanvasBlob(canvas) {
      return new Promise((resolve) => {
        canvas.toBlob((blob) => {
          resolve(blob);
        }, 'image/jpeg', 0.8);
      });
    },
    savePdf() {
      const quality = 3;
      this.savingPdf = true;
      const self = this;
      html2canvas(document.querySelector('#pdf'), { scale: quality })
        .then((canvas) => {
          // eslint-disable-next-line
          const pdf = new jsPDF('p', 'mm', this.format);
          const x = this.format === 'a4' ? 211 : 297;
          const y = this.format === 'a4' ? 298 : 420;
          pdf.addImage(canvas.toDataURL('image/jpg'), 'JPG', 0, 0, x, y);
          pdf.save('calendar');
          self.savingPdf = false;
        });
    },
  },
};
</script>

<style scoped>
.page-wrapper--fixed {
  max-height: 100vh;
  overflow: hidden;
}
.gallery {
  text-align: left;
}
.gallery--active {
  margin-left: 4rem;
}
.desire-add__file-label--gallery {
  opacity: 0;
}
.gallery__image {
  width: 30rem;
  display: block;
  height: 30rem;
  object-fit: cover;
}
@media (max-width: 768px) {
  .gallery--active {
    margin-left: 0;
  }
  .desire-add__file-label--gallery {
    display: none;
  }
.gallery__image {
  width: 10rem;
  display: block;
  height: 10rem;
  object-fit: cover;
}
}
.gallery a {
  color: #000;
  font-size: 2rem;
  display: block;
  margin-bottom: 1rem;
}
.gallery__images {
  display: flex;
  flex-wrap: wrap;
}
.select-format {
  display: block;
  width: 100%;
  text-align: center;
}
.download {
  flex-wrap: wrap;
}
.download select {
  height: 4rem;
  width: 20rem;
  font-size: 2rem;
  border: 0.2rem solid #DFB3E6;
  padding: 0 1rem;
}
</style>
