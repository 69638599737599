<template>
  <div class="footer">
    <div class="footer-links">
      <div class="footer-link">
        © {{year}} Letique Cosmetics
      </div>
      <a href="/offer.pdf" target="_blank" class="footer-link">Публичная оферта</a>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    year() {
      return (new Date()).getFullYear();
    },
  },
};
</script>
