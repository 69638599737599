<template>
  <div class="login">
    <div class="login-block">
      <div class="login-block__buttons">
        <button class="login-block__button login-block__button--active">
          авторизация
        </button>
      </div>
      <form class="form" @submit.prevent="login">
        <div class="form-groups">
          <div class="form-group">
            <div class="form-group__top">
              <label for="email" class="form-group__label">Email</label>
            </div>
            <div class="form-group__block">
              <input id="pass" class="form-group__input"
                type="email" placeholder="Введите email"
                required
                v-model="user.email"
              >
            </div>
          </div>
          <div class="form-group">
            <div class="form-group__top">
              <label for="pass" class="form-group__label">Пароль</label>
            </div>
            <div class="form-group__block">
              <input id="pass" class="form-group__input"
                placeholder="Введите пароль"
                :type="isPassword ? 'password' : 'text'"
                required
                v-model="user.password"
                >
            </div>
            <span class="error">{{error}}</span>
          </div>
        </div>
        <button class="button__form"
            type="submit"
            :disabled="loading"
          >
          {{ loading ? 'Загрузка...' : 'Войти в личный кабинет' }}
        </button>
      </form>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      isPassword: true,
      user: {
        email: null,
        password: null,
      },
      error: null,
      loading: false,
    };
  },
  mounted() {
    if (this.$route.query.email) {
      this.user.email = this.$route.query.email;
      this.user.password = this.$route.query.password;
      this.login();
    } else if (localStorage.getItem('token')) {
      this.$emit('setLoggedIn');
    }
  },
  methods: {
    async login() {
      this.loading = true;
      this.error = '';
      this.user.guide = 3;
      const response = await this.$request.post('/api/guide/login', this.user);
      if (response.status !== 200 || !response.data.success) {
        this.error = 'Неправильный email или пароль';
      } else {
        localStorage.setItem('token', response.data.result.token);
        this.$emit('setLoggedIn');
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.bg--main {
  height: 100vh;
  overflow: auto;
}
.error {
  color: #f00;
  font-size: 1.6rem;
  margin-top: 1rem;
  display: block;
}
</style>
